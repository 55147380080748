import React from 'react';
import InitiativesCard from './../common/components/events/events';
import JoinSlack from '../common/components/slack/Slack';
import './../styles/styles.css';

import ComputerPic from './../images/computer.png';
import PinkWave from './../images/header-waves/pink-wave.svg';
import SoftwareEngPic from './../images/softwareeng.png';

const Initiatives = () => {
    return (
        <div style={{ height: "100%", display: "block", margin: "0", overflow: "hidden" }}>
            <section style={{ background: '#F2D0C8', paddingTop: '10%' }}>
                <div className="container">
                    <div className="row reverse-col">
                        <div className="col-lg-6 m-auto">
                            <h1>Initiatives</h1>
                            <br />
                            <h4 style={{ lineHeight: '160%', fontFamily: 'nunito' }}>
                                WITS delivers a series of initiatives designed to inspire and equip.
                                We provide our community with access to informative panels, educationals, workshops, inspirational speakers, and SheHacks.
                            </h4>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5 py-5">
                            <img src={ComputerPic} className="mobileimg" alt="main" />
                        </div>
                    </div>
                </div>
            </section>
            <img src={PinkWave} className="w-100 mb-5" style={{ marginTop: '-5px' }} alt="wave"/>
            <section className="py-5">
                <InitiativesCard
                    title="Events & Educationals"
                    date=""
                    desc="Throughout the year, we will be hosting a variety of workshops, panels, and educationals. Join us for recruiting tips and tricks, casual community work sessions, and informational events that will showcase what tech has to offer!"
                    buttonLabel="See our events"
                    onClickHandler="clicked"
                    link="/events"
                    image=""
                />
                <InitiativesCard
                    title="Mentorship & Projects"
                    date=""
                    desc="The WITS Mentorship Programs connect young women who want to expand their knowledge and gain footing in the tech world with upper-years who have that experience and knowledge as part of a year-long project development program."
                    buttonLabel="Our programs"
                    onClickHandler="clicked"
                    link="/mp"
                    image=""
                />
                <InitiativesCard
                    title="Blog & Podcast"
                    date=""
                    desc="Our blog and podcast feature stories and insights from women in the tech industry. Read up on tech tips and exciting tid-bits, and listen to our podcast for insightful stories about navtigating the industry, told directly by the incredible women themselves!"
                    buttonLabel="Learn more"
                    onClickHandler="clicked"
                    link="/blog"
                    image=""
                />
            </section>
            <JoinSlack />
            <div style={{ background: '#F1EFE2' }} className="py-5">
                <div class="container">
                    <h2>Upcoming Events</h2>
                    <br /><br />
                    <InitiativesCard
                        title="Annual General Meeting"
                        date="September 13, 2021"
                        desc="Join our official kick off to the WITS 2021-2022 year! "
                        buttonLabel="Learn more"
                        onClickHandler="clicked"
                        link="https://wits-uwo.ca/events"
                        image={SoftwareEngPic}
                    />
                    <InitiativesCard
                        title="Industry Tools You Don't Learn in School"
                        date="TBD"
                        desc="Learn the basics of GitHub, Jira & other developer applications to add to your technical toolkit!"
                        buttonLabel="Learn more"
                        onClickHandler="clicked"
                        link="https://wits-uwo.ca/events"
                        image={SoftwareEngPic}
                    />
                    <InitiativesCard
                        title="No More Ghosting: Intro to Networking"
                        date="TBD"
                        desc="Learn the tips and tricks to networking with industry professionals right around recruiting season!"
                        buttonLabel="Learn more"
                        onClickHandler="clicked"
                        link="https://wits-uwo.ca/events"
                        image={SoftwareEngPic}
                    />
                    <InitiativesCard
                        title="Spooky Struggles: It's Okay to Fail in Tech"
                        date="TBD"
                        desc="Join a candid conversation about the struggles and personal experiences we've had studying and working in the tech field!"
                        buttonLabel="Learn more"
                        onClickHandler="clicked"
                        link="https://wits-uwo.ca/events"
                        image={SoftwareEngPic}
                    />
                    <InitiativesCard
                        title="Tech Expo Panel"
                        date="TBD"
                        desc="Join & network with panelists from startups and other industries to explore niche tech opportunities!"
                        buttonLabel="Learn more"
                        onClickHandler="clicked"
                        link="https://wits-uwo.ca/events"
                        image={SoftwareEngPic}
                    />
                </div>
            </div>
        </div>
    )
}

export default Initiatives;