import React from "react";
import "../../styles/styles.css";


const EventCard = ({ title, desc, date }) => {
  return (
    <div class="slider m-auto">
      <div class="event-card m-3" style={{ width: '250px', height: '260px'}}>
        <div class="card-content">
          <h3>{title}</h3>
          <p>{desc}</p>
          <p><b>{date}</b></p>
        </div>
      </div>
    </div>
  );
};
export default EventCard;