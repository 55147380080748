//@flow
import React from "react";
import styled from "styled-components";
import "./../../styles/styles.css";

const CardContent = styled.div`
  box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 20px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

const PartnerCard = ({ link, image, name, company, position }) => {
  return (
    <div className="container">
      <a className="partner-card m-3" href={link} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
        <CardContent style={{ textAlign: 'center', padding: '5% 5%', height: '100%', background: '#fff' }}>
          <img src={image} className="card-img" style={{ display: "unset" }} width="100%" alt="main"/>
          <h5 style={{ color: '#0A2338', fontWeight: "bold" }}><br />{name}</h5>
          <h6 style={{ color: '#0A2338', fontWeight: "bold" }}>{company}</h6>
          <h6 style={{ fontWeight: "normal" }}>{position}</h6>
        </CardContent>
      </a>
    </div>
  );
};
export default PartnerCard;
