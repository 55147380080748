import React from "react";
import "../../../styles/styles.css";

import Slack1 from '../../../images/slack1.png';
import Slack2 from '../../../images/slack2.png';
import Logo from '../../../images/slacklogo.png';
import Button from "../button/Button";

const JoinSlack = () => {
    return (
        <section style={{ backgroundColor: "#0A2338", padding: "5% 0", marginTop: "1%" }}>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 m-auto slack-img">
                            <img src={Logo} alt="main pic" />
                        </div>
                        <div className="col-lg-8 m-auto slack-text">
                            <h2 style={{ color: '#F1EFE2' }}>Join Our Slack Community</h2>
                        </div>
                    </div>
                </div>
                <br />
                <h5 className="slack" style={{ textAlign: 'center', color: '#F1EFE2' }}>
                    Join a community of women supporting women in tech.
                    <br />Our Slack community is open to everyone for free!
                </h5>
                <section style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '50%' }}>
                    <div className="row d-flex justify-content-center">
                  
                        <div className="col-lg-4 col-md-10 col-sm-12 col-12">
                            <br />
                            <img src={Slack1} className="mobileimg" width="100%" alt="pic" />
                            <br />
                        </div>
                        <div className="col-lg-4 col-md-10 col-sm-12 col-12 ">
                            <br />
                            <img src={Slack2} className="mobileimg" width="100%" alt="pic" />
                            <br />
                        </div>
                        {/* <div className="col-lg-4 col-md-10 col-sm-12 col-12">
                            <br />
                            <img src={Slack3} className="mobileimg" width="100%" alt="pic" />
                            <br />
                        </div> */}
                    </div>
                    <br />
                    <h5 style={{ textAlign: 'center', color: '#F1EFE2', fontSize: '1em' }}>
                        <em>*Available to paid WITS+ VIP members only.</em>
                    </h5>
                    <br />
                    <div className="row justify-content-center">
                        <Button type="membership" label="Join Our Slack" size="membership" link="https://join.slack.com/t/womenintechso-mwa7748/shared_invite/zt-wkerr7ep-Y6Ak9QKNjxqItXRNAG72PQ" onClick={ () => {console.log("Join Slack :))")}}/>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default JoinSlack;
