import React from "react";
import PPCard from "./comp/pecard";
import exposure from "../images/exposure.png";
import skills from "../images/skills.png";
import support from "../images/support.png";
import hackwithheart from "../images/hackwithheart.png";
import purpose from "../images/mp1.png"
import ada from "../images/membershippic.png"
import bigsister from "../images/mp/bigsister.png"
import humane_society from "../images/mp/humane_society.png"
import proptech from "../images/mp/proptech.png"
import aisec from "../images/mp/aisec.png"
import project1 from "../images/mp/project1.png"
import project2 from "../images/mp/project2.png"
import project3 from "../images/mp/project3.png"
import "../styles/styles.css";
import ProjectCard from './../common/components/mp/project';
import Projectlong from './../common/components/mp/projectcardlong';
//import Timeline from './../common/components/mp/timeline';
import Slider from './../common/components/slider/Slider';
//import { StrictMode } from "react";


const eventsArr = [
  [
      "Sortify",
      "Sortify is a playlist organizing web app that uses the Spotify API to analyze the attributes of each song in a user’s Liked Songs and sorts them accordingly into pre-set moods. This ensures that the newly curated playlist only contains songs the user already likes.",
      
  ],
  [
      "TrackPack",
      "TrackPack is the all-in-one mobile app that updates users on the statuses of all their packages. This platform uses an API within Android Studio to request shipment data from over 15 different postal carriers, giving you the shipment details you need, all in one place.",
  ],
  [
      "7 Days a Week",
      "7 Days a Week is a productivity app that auto-schedules blocks of time to complete tasks in addition to regular, manually-added tasks. This allows users to save time as well as recalibrate the time blocks if time requirements change.",
      
  ],
  [
      "Nomnom",
      "Nomnom used Python Flask, Google Maps API, Firebase and Figma to design and develop a web application that allows users to recommend restaurants and share their food experience with friends interactively.",
      
  ],
  
]

const Ada = () => {
  return (
    <div style={{ height: "100%", display: "block", margin: "0", overflow: "hidden" }}>
      <section style={{ backgroundColor: "#E68976", paddingTop: "7%" }}>
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-lg-6 m-auto">
              <h1>
                Mentorship & <br />
                Projects
              </h1>
            </div>
            <div className="col-lg-6">
              <h4 style={{ fontFamily: "nunito",fontWeight:'bold',color:'black' }}>
                Connecting young women+ who want to expand their knowledge and
                gain footing in the tech world with upper-years who have that
                experience and knowledge as part of a year-long project
                development program.
              </h4>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </section>
      <section style={{ paddingBottom: "5%", paddingTop: "2%" }}>
        <div className="container">
          <div className="row pt-4">
            <div class="col=lg-5 col-md-5 col-sm-12 mt-4">
                <img src={purpose} width="80%" height="auto" alt="main pic"/>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 m-auto">
              <h2> Purpose</h2>
                <p>
                Our objective is to provide females and non-binary individuals with opportunities to engage in the tech community and to gain hands-on experience with new technologies. 
                We by providing mentorship to students through our side project programs. Our two flagship programs encourage students to take their learning outside of the classroom 
                and to gain practical experience by developing a project from ground-up.
                </p>
            </div>
            <div className="col-12 m-auto">
              <h2> Learning Goals</h2><br/>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4 col-md-4 col-sm-12 mt-4">
              <img src={exposure} width="100%" height="auto" alt="pic"/>
            </div>
            <div class="col-lg-4  col-md-4 col-sm-12 mt-4">
              <img src={skills} width="100%" height="auto" alt="pic"/>
            </div>
            <div class="col-lg-4  col-md-4 col-sm-12 mt-4">
              <img src={support} width="100%" height="auto" alt="pic"/>
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#E68976", paddingBottom: "2%" }}>
        <div className="container py-5">
          <div className="row py-5 px-3">
            <div className="col-lg-6 m-auto">
              <h1>
                Ada Mentorship <br /> Program
              </h1>
            </div>
            <div className="col-lg-6">
              <h4 style={{ color: "#0A2338", fontFamily: "nunito" }}>
              Connecting young women who want to expand their knowledge and gain footing in the tech world with upper-years who have that experience and knowledge as part of a year-long project development program.
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section style={{ paddingTop: "5%", paddingBottom: "5%" }}>
        <div className="container">
          <div className="row">
            <div class="col-lg-4 col-md-4 horizontal-center w-100 p-4">
              <img
                src={ada}
                alt="hwh pic"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-lg-8 col-md-8 p-4">
              <h2>
                Not your Typical Mentorship Program.
              </h2>
              <p>
                Ada Mentorship is a <b>side project program</b> connecting young women who want to expand their knowledge and gain footing in the tech world with upper-year students and professional mentors.
              </p>
              <br></br>
              <h2>The Ada Vision</h2>
              <p>
              We aim to grow our Ada cohort to over <b>100 students to produce over 12 projects.</b> Students will gain practical experience by working with today’s technologies, sponsored by tech companies.
              </p>
            </div>
          </div>
         </div> 
        </section>
          {/* NEED EXACT ICON GRAPHICS */}
          {/* <div className="row">
            <h2>Takeaways from the Program</h2>
            <div class="row">
              <PPCard
                image={ada}
                desc="Gain exposure to a variety of technologies outside the classroom key to their tech role of interest."
              />
              <PPCard
                image={ada}
                desc="Practical skills to excel in the tech industry."
              />
              <PPCard
                image={ada}
                desc="Strong support network to create a welcoming journey learning how to ideate, design think, and develop"
              />
            </div>
          </div> */}
          <br></br>
          <div style={{ background: '#F1EFE2'  }} className="py-5">
          <div class="container" >
            <h2>Become a Mentee</h2>
            <p>
              We’re so excited that you are interested in joining the 2021-2022 cohort! Mentee applications open <b>Sep 20</b>. Be sure to check back when the application opens.          </p>
              {/* NEED TO IMPLEMENT BUTTON */}
              <p>Join our <a href="http://eepurl.com/gZ4Pxv"><b>mailing list </b></a>to stay up to date!</p>
              
             
            
          </div>
                 
          <br></br>

          <div class="container">          

            <h2>Become a Mentor</h2>
          </div>
          <div className="container">            <br></br>
            <p>
              Ada mentors have the opportunity to lead and provide career and project advice for Ada teams within the program. 
              Their technical expertise and knowledge within the tech industry provides valuable insight on each team’s project, leading their product to</p>
          
          <div className="row">
              <PPCard
                title="Student Mentors"
                desc="Student mentors are responsible for being each team’s lead and providing general university and technical project guidance."
              />
              <PPCard
                title="Professional Mentors"
                desc="Professional mentors are responsible for providing team guidance, relevant to their own fields. They also help provide career insights and project advice to women in tech!"
              />
          </div>
          </div>
          </div>
          <br></br>
          


          <section className="py-5">
          <div className="container">
                    <h3 >A LOOK BACK</h3>
                    <h2 >Past Projects</h2>
                    <br></br>
                    <br></br>
                < Projectlong
                    title="PlatePal" 
                    subtitle="Awarded: Best Implementation"
                    date="" 
                    desc="PlatePal is a web app that aims to allow users to tailor their meal preferences and discover thousands of new recipes. Users are able to save time and money while also reducing food waste."   
                    image={project1}
                    
                 
                />
                
                < ProjectCard 
                    title="PrepU" 
                    subtitle="Awarded: Best Overall"
                    date="" 
                    desc="PrepU is a web app that uses facial recognition and facial sentiment analysis to assist individuals preparing for job interviews. The application provides practice interview questions for the user, and as the user provides a response, the app analyzes the user’s face and speech to identify the quality of their response." 
                    image={project2}
                />
                < ProjectCard
                    title="StreamLine"
                    subtitle="Awarded: Best UX/UI Design"
                    date="" 
                    desc="StreamLine is an organizational tool that compiles job positings from across the web into one place for job hunters to view. It enables users to keep track of their activity and progress during their job hunt." 
                    image={project3}
                />
                </div>
            </section>
            <div className="container">
            <h2>More Featured Projects</h2>
            <br></br>
                    <div style={{backgroundColor: '#F1EFE2', borderRadius: '20px', height: '430px'}}>
                    <div class="slider m-3" style={{paddingTop: '10px'}}>
                        <Slider type="mp" arr={eventsArr} />
                        </div>
                    </div>
                </div>

          
          {/* <div className="row">
            <h2>Mentee Testimonials</h2>
          </div>
          <div className="row">
            <p>Coming soon!</p>
            <HWCard
              image={abby}
              name="Abby Lui"
              titl="Designer Mentee"
              testimonial="A group of female students came in with different experiences and levels of expertise. 
									Hack with Heart encouraged collaboration and innovative thinking to create a solution that positively and directly impacts an 
									organization well known across the nation. "
            />
          </div>
          <div className="row">
            <h2>Past Projects</h2>
          </div>
          <div className="row">
            <p>Coming soon!</p>
          </div> */}
       
      <br></br>
      <br></br>
      <br></br>
      <section style={{ backgroundColor: "#E68976", paddingBottom: "2%" }}>
        <div className="container py-5">
          <div className="row py-5 px-3">
            <div className="col-lg-8 m-auto">
              <h1>
                Hack with <br /> Heart
              </h1>
            </div>
            <div className="col-lg-4">
              <img src={hackwithheart} width="80%" height="auto" alt="pic"/>
            </div>
          </div>
        </div>
      </section>

      <section style={{ paddingTop: "5%", paddingBottom: "5%" }}>
        <div className="container">
          <div className="row">
            <p>
              Hack with Heart is a M&P flagship summer project program. 
              In this program, teams of students and mentors help nonprofits solve problems by crafting effective products and solutions using technology. 
              Each team is comprised of software developers, product designers, business analysts, and experienced mentors to help equip nonprofits with the tools they need to succeed.
              <br></br>
              In the past, Hack with Heart has partnered with the following organizations:
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={humane_society}  style={{textAlign:'center'}} width="100%" height="auto" alt="pic"/>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={proptech}  style={{textAlign:'center'}} width="100%" height="auto" alt="pic"/>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={aisec}  style={{textAlign:'center'}} width="100%" height="auto" alt="pic"/>
            </div>
          </div>
          <br></br>
    
          <div className="row">
            <br></br>
            <p><b>Applications open: March</b></p>
            <p>If you are interested in being a mentor for the program, please fill out this form and we will be in contact with you: <a href="https://bit.ly/3Ag0asB">https://bit.ly/3Ag0asB</a></p>
          </div>
          <br></br>
          <div className="row">
            <br></br>
            <p>Are you a non-profit and looking to partner with us? Please fill out the Hack with Heart interest form here.<a href="https://bit.ly/37kibtv">https://bit.ly/37kibtv</a></p>
          </div>

        </div>
      </section>

      <section style={{ backgroundColor: "#E68976", paddingBottom: "2%" }}>
        <div className="container py-5">
          <div className="row py-5 px-3">
            <div className="col-lg-8 m-auto">
              <h1>
                Big CSter <br /> Program
              </h1>
            </div>
            <div className="col-lg-4">
              <img src={bigsister} width="100%" height="auto" alt="pic"/>
            </div>
          </div>
        </div>
      </section>

      <section style= {{ paddingTop: "5%"}}>
        <div className="container">
          <div className="row">
            <p>
              WITS Mentorship and Projects is so excited to launch our first official Big CSters mentorship program! 
              This program is aligned to ensure meaningful connections are being built within our university community by matching first and second year students with upper years. 
              The year long mentorship program will feature weekly socials, bi-weekly workshops, exclusive fireside chats and networking opportunities. The program is an excellent fit for anyone looking to make more friends in the tech community and gain valuable insight from older students on topics ranging from school, career, balancing extra curriculars, personal connections and more! 
              <br></br>
              <br></br>
              Applications for Mentors will be released in early September with mentee applications being released mid to late September. Be on the look out on our socials for key deadlines!
            </p>
          </div>
        </div>
      </section>


    </div>
  );
};
export default Ada;
