import React from "react";
import styled from "styled-components";
import google from "../images/google.png";
import cloud from "../images/cloud.png";
import BitcoinMoney from "../images/BitcoinMoney.jpeg";
import ShehacksRedefined from "../images/ShehacksRedefined.png";
import SelflessLeadership from "../images/SelflessLeadership.jpeg";
import "../styles/styles.css";
import "../styles/blog.css";

const StyledSimpleA = styled.a`
  color: #e68976;
  text-decoration: none;
  &:hover {
    color: #edb4a8;
    opacity: 0.5;
    text-decoration: none;
  }
`;
const StyledA = styled.a`
  background-color: #e68976;
  border: none;
  color: white;
  font-style: normal;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 50px;
  &:hover {
    opacity: 0.5;
    transition: all 0.3s ease 0s;
  }
`;
const StyledEffect = styled.a`
  background: linear-gradient(
    to bottom,
    var(--mainColor) 0%,
    var(--mainColor) 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  color: #000;
  text-decoration: none;
  transition: background-size 0.6s;
  &:hover {
    background-size: 4px 50px;
    text-decoration: none;
    color: #000;
  }
`;
const Blog = () => {
  return (
    <div style={{ height: "100%", display: "block", margin: "0", overflow: "hidden" }}>
      <section
        style={{
          backgroundColor: "#F4D089",
          paddingTop: "12%",
          paddingBottom: "5%",
        }}
      >
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6 m-auto">
              <h1>
                Blog &<br />
                Podcast
              </h1>
            </div>
            <div className="col-lg-6 py-5">
              <h4
                style={{
                  fontFamily: "nunito",
                  fontWeight: "600",
                  lineHeight: "180%",
                  color: "#0A2338",
                }}
              >
                Hear and read the thoughts of powerful women+ in tech! Join us as
                we delve into their stories and insights.
              </h4>
            </div>
          </div>
          <br />
        </div>
      </section>
      <div style={{ marginTop: "5%" }}>
        <div className="container">
          <div className="row reverse-col" style={{ marginBottom: "0px" }}>
            <div className="col">
              <h3>PODCAST</h3>
              <h2 style={{ color: "#0A2338" }}>Trailblaze-hers</h2>
              <br />
              <h4>
                <StyledEffect
                  className="buttonHover"
                  href="https://open.spotify.com/show/5as4zJCEU6zHAv0hwdZGFE?si=InbX0VusQO6vwCWbeDd2EA"
                  target="_blank"
                >
                  TrailBlaze-Hers is now available on Spotify!
                </StyledEffect>
              </h4>
              <h5
                style={{
                  color: "#0A2338",
                  fontFamily: "nunito",
                  lineHeight: "160%",
                }}
              >
                The TrailBlaze-Hers Podcast tells the stories of influential
                women+ in tech - one story at a time.
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-5 col-md-5 text-center">
              <StyledSimpleA
                href="https://open.spotify.com/show/5as4zJCEU6zHAv0hwdZGFE?si=InbX0VusQO6vwCWbeDd2EA"
                target="_blank"
              >
                <img src={google} alt="blog pic" style={{width: '100%'}}/>
              </StyledSimpleA>
              <h5 style={{ textAlign: "center", color: "#0A2338" }}>
                Google's VP of User <br /> Experience
                <br />
              </h5>
              <StyledA
                href="https://open.spotify.com/show/5as4zJCEU6zHAv0hwdZGFE?si=InbX0VusQO6vwCWbeDd2EA"
                style={{ color: "white", fontFamily: "Rubik" }}
              >
                Listen on Spotify
              </StyledA>
            </div>
            <div className="col-2 col-md-2"></div>

            <div className="col-5 col-md-5 text-center">
              <StyledSimpleA
                href="https://open.spotify.com/show/5as4zJCEU6zHAv0hwdZGFE?si=InbX0VusQO6vwCWbeDd2EA"
                target="_blank"
              >
                <img src={cloud} alt="blog pic" style={{width: '100%'}}/>
              </StyledSimpleA>
              <h5 style={{ textAlign: "center", color: "#0A2338" }}>
                In the Cloud with Erin <br /> Chapple
                <br />
              </h5>
              <StyledA
                href="https://open.spotify.com/show/5as4zJCEU6zHAv0hwdZGFE?si=InbX0VusQO6vwCWbeDd2EA"
                style={{ color: "white", fontFamily: "Rubik" }}
              >
                Listen on Spotify
              </StyledA>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "5%", backgroundColor: "#F2D0C8" }}>
        <div className="container">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <br />
              <h3>MEDIUM BLOG</h3>
              <h2>
                Insights, inspirations, <br /> and more!
              </h2>
            </div>
          </div>
          <br/>
          <br/>
          <div className="row">
            <div className="col">
              <StyledSimpleA href="https://wits-uwo.medium.com/btc-better-have-my-money-big-tech-and-bitcoin-8803603ce1d9" target="_blank">
                <img src={BitcoinMoney} alt="blog pic" style={{width: '100%'}}/>
              </StyledSimpleA>
              <h5 style={{ textAlign: "center", color: "#0A2338" }}> <br /> BTC Better Have My Money: Big Tech and Bitcoin </h5>
            </div>
            <div className="col">
              <StyledSimpleA href="https://wits-uwo.medium.com/re-introducing-shehacks-13d09c10a149" target="_blank">
                <img src={ShehacksRedefined} alt="blog pic" style={{width: '100%'}} />
              </StyledSimpleA>
              <h5 style={{ textAlign: "center", color: "#0A2338" }}> <br /> Re-Introducing SheHacks+ </h5>
            </div>
            <div className="col">
              <StyledSimpleA href="https://wits-uwo.medium.com/selfless-leadership-c2c5fb1b1b6f" target="_blank">
                <img src={SelflessLeadership} alt="blog pic" style={{width: '100%'}}/>
              </StyledSimpleA>
              <h5 style={{ textAlign: "center", color: "#0A2338" }}> <br />Selfless Leadership </h5>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};
export default Blog;
