// @flow
import React, { useCallback } from "react";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import styled from "styled-components";


const StyledButton = styled.button`
  background: ${props =>
    props.type === "tertiary" ? "#0A2338"
      : props.type === "secondary" ? "#FAF0E6"
        : "#E68976"};
  color: ${props =>
    props.type === "tertiary" ? "white"
      : props.type === "secondary" ? "black"
        : "white"};
  font-size: ${props =>
    props.size === "small" ? "16px"
      : props.size === "medium" ? "14px"
        : "16px"};
  padding:  ${props =>
    props.size === "small" ? "10px 25px"
      : props.size === "medium" ? "11px 20px"
        : props.size === "membership" ? "20px 40px" : "20px 40px"};
  border-radius:  ${props =>
    props.size === "small" ? "10px"
      : props.size === "membership" ? "50px" : "3em"};
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  &:hover {
    background-color: ${(props) =>
      props.type === "membership"
        ? "#e69f91"
        : props.type === "tertiary"
        ? "#183045"
        : ""};
    border-radius: ${(props) => (props.type === "tertiary" ? "50px" : "")};
  }
`;
const StyledA = styled.a`
  text-decoration: none;
  color: ${(props) =>
    props.type === "tertiary"
      ? "white"
      : props.type === "secondary"
      ? "#E68976"
      : "white"};
  &:hover {
    color: ${(props) =>
      props.type === "secondary" ? "black" :
      "white"};
    text-decoration: none;
    transition: 0.3s;
  }
`;
export type buttonProps = {
  type: String,
  size: string,
  label: string,
  onClick: (string) => void,
  link?: string,
};

const Button = ({ size, label, onClick, type, link }: buttonProps) => {
  const clickHandler = useCallback(() => onClick(), [onClick]);
  return (
    <StyledButton type={type} size={size} onClick={clickHandler}>
      <StyledA href={link} type={type}>
        {label}
      </StyledA>
    </StyledButton>
  );
};

export default Button;
