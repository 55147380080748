//@flow
import React from "react";
import styled from "styled-components";

const StyledA = styled.a`
  color: #0a2338;
  padding: 10px 2px;
  &:hover {
    color: #e57154;
    text-decoration: none;
    transition: 0.3s;
  }
`;
const StyledImage = styled.img`
  width: 60%;
  height: auto;

  &:hover {
    opacity: 0.5;
    transition: all 0.3s ease 0s;
  }
`;
const Company = ({link, image}) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-4 col-4 m-auto p-4">
      <StyledA href={link} target="_blank">
        <StyledImage src={image} width="100%" />
      </StyledA>
    </div>
  );
};
export default Company;
