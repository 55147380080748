import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './common/components/NavBar/NavBar';
import Footer from './common/components/footer/Footer';
import MembershipPage from './membership/membership.page';
import MembershipSuccessPage from './membership/payment/success.page';
import MainPage from './main/main.page';
import PartnersPage from './partner/partner.page';
import PartnerSuccessPage from './partner/payment/success.page';
import MPPage from './mp/mp.page';
import BlogPage from './blog/blog.page';
import InitiativesPage from './initiatives/initiatives.page';
import ProfilePage from './about/about.page';
import EventsPage from './events/events.page';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact><MainPage /></Route>
        <Route path='/mp' exact><MPPage /></Route>
        <Route path='/blog' exact><BlogPage /></Route>
        <Route path='/initiatives' exact><InitiativesPage /></Route>
        <Route path='/about' exact><ProfilePage /></Route>
        <Route path='/events' exact><EventsPage /></Route>
        <Route path='/membership' exact><MembershipPage /></Route>
        <Route path='/success/:id' exact><MembershipSuccessPage /></Route>
        <Route path='/partners' exact><PartnersPage /></Route>
        <Route path='/sponsor/success/:id' exact><PartnerSuccessPage /></Route>
        <Route render={() => <h1>Not Found</h1>} />
      </Switch>
      <Footer />
    </Router>
  );
}
export default App;
