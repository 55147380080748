import React from 'react';
import styled from 'styled-components';

import facebook from '../../../images/facebook_b.png';
import linkedin from '../../../images/linkedin_b.png';
import instagram from '../../../images/instagram_b.png';
import medium from '../../../images/medium_b.png';
import spotify from '../../../images/spotify_b.png';

const StyledFooter = styled.div`
   margin-top: 5%;
   margin-bottom: 5%;
   z-index: 3000;
`;

const StyledDiv = styled.div`
    font-family: 'Open Sans', sans-serif;
`;

const StyledH = styled.h6`
    font-weight: 700;
    font-size: 16px;
    color: #0A2338;
    margin: 0px;
`;

const StyledP = styled.p`
    margin: 5px 0px 0px 0px;
    font-size: 14px;
`;

const StyledLinks = styled.a`
    font-size: 14px;
    font-family: "Nunito Sans";
    color: #0A2338;
    text-decoration: none;
    &:hover{
        color: #E57154;
        transition: all 0.3s ease;
    }
`;

const StyledUl = styled.ul`
    float: right;
    @media (max-width: 920px){
        float: left;
        padding-left: 0px;
        margin-top: 5px;
        margin-left: 0px;
    }
`;

const StyledLi = styled.li`
    list-style-type: none;
    display: inline-block;
`;

const StyledImg = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    &:hover {
        opacity: 0.5;
        transition: all 0.3s ease;
    }
`;

const Footer = () => {
    return (
        <StyledFooter className="container">
            <div className="row">
                <StyledDiv className="col-lg-5 col-sm-12 col-12">
                    <StyledH>Women+ in Technology Society</StyledH>
                    <StyledP>
                        1151 Richmond St, London, ON N6A 3K7<br />
                        <StyledLinks href="http://eepurl.com/gZ4Pxv">Join our newsletter!</StyledLinks><br />
                        <StyledLinks href="mailto:wits.uwo@gmail.com">wits.uwo@gmail.com</StyledLinks><br />
                        Made with &#10084;&#65039; in London, ON
                    </StyledP>
                </StyledDiv>
                <StyledDiv className="col-lg-7 col-sm-12 col-12">
                    <StyledUl>
                        <StyledLi>
                            <a href="http://www.facebook.com/wits.uwo/" target="_blank" rel="noreferrer"><StyledImg src={facebook} alt="facebook" /></a>
                        </StyledLi>
                        <StyledLi>
                            <a href="https://www.linkedin.com/company/uwowits/" target="_blank" rel="noreferrer"><StyledImg src={linkedin} alt="linkedin" /></a>
                        </StyledLi>
                        <StyledLi>
                            <a href="http://www.instagram.com/wits.uwo/" target="_blank" rel="noreferrer"><StyledImg src={instagram} alt="instagram" /></a>
                        </StyledLi>
                        <StyledLi>
                            <a href="https://wits-uwo.medium.com/" target="_blank" rel="noreferrer"><StyledImg src={medium} alt="medium" /></a>
                        </StyledLi>
                        <StyledLi>
                            <a href="https://open.spotify.com/show/5as4zJCEU6zHAv0hwdZGFE?si=wPx63QWfS02WjwW3dDDylA" target="_blank" rel="noreferrer"><StyledImg src={spotify} alt="spotify" /></a>
                        </StyledLi>
                    </StyledUl>
                </StyledDiv>
            </div>
        </StyledFooter>
    )
}

export default Footer; 
