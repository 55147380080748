import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "./../common/components/slider/Slider";
import Company from "./cards/company";
import Button from "../common/components/button/Button";
import mainpic from "../images/mainpic.png";
import yellowwave from "../images/header-waves/yellow-wave.svg";
import twitterlogo from "../images/twitterlogo.png";
import fblogo from "../images/fblogo.png";
import tdlogo from "../images/tdlogo.png";
import microsoftlogo from "../images/microsoftlogo.png";
import googlelogo from "../images/googlelogo.png";
import konradlogo from "../images/konradlogo.png";
import cpplogo from "../images/cpplogo.png";
import ibmlogo from "../images/ibmlogo.png";
import cblogo from "../images/cblogo.png";
import ubisoftlogo from "../images/ubisoftlogo.png";
import lllogo from "../images/lllogo.png";
import sblogo from "../images/sblogo.png";
import erin from "../images/past-partners/erin.png";
import glaze from "../images/past-partners/glaze.png";
import melissa from "../images/past-partners/melissa.png";
import stephanie from "../images/past-partners/stephanie.png";
import lily from "../images/past-partners/lily.png";

import Modal from "./modal/Modal";

const partnersArr = [
  [
    "https://www.linkedin.com/in/erinmchapple/",
    erin,
    "Erin Chapple",
    "Microsoft",
    "Corporate VP, Azure Compute"
  ],
  [
    "https://www.linkedin.com/in/glazemartillano/",
    glaze,
    "Glaze Martillano",
    "Facebook",
    "Talent Acquisition"
  ],
  [
    "https://www.linkedin.com/in/melissasariffodeen/",
    melissa,
    "Melissa Sariffodeen",
    "Canada Learning Code",
    "Co-Founder & CEO"
  ],
  [
    "https://www.linkedin.com/in/stephthi/",
    stephanie,
    "Stephanie Nyugen",
    "Coinsquare",
    "Director of Design"
  ],
  [
    "https://www.linkedin.com/in/lilytse/",
    lily,
    "Lily Tse",
    "Think Dirty",
    "Founder & CEO"
  ]
]

const styledSection = styled.section`
  padding: 4rem 0 0 0;
`;
const SliderDiv = styled.div`
  background-color: #b3e2d4;
  border-radius: 20px;
  padding: 2%;
  padding-bottom: 7%;
`;

const StyledButton = styled.button`
  background: #E68976;
  color: white;
  font-size: 18px;
  padding:  20px 40px;
  border-radius: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  &:hover {
    background-color: #e69f91;
    border-radius: ""};
  }
`;

const StyledH1 = styled.h1`
  color: #0a2338;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  line-height: 110%;
  font-size: 3.5rem;
`;
const StyledH4 = styled.h4`
  font-family: Helvetica;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  color: #0a2338;
`;

const Partner = () => {
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    setShow(false)
  }, []);

  function handleClick() {
    console.log("click clack");
  }
  return (
    <div style={{ height: "100%", margin: "0" }}>
      <section style={{ background: "#F4D089", paddingTop: "7%" }}>
        <div className="container">
          <div className="row reverse-col">
            <div className="col-lg-6 m-auto">
              <h1>Partners</h1>
              <br />
              <h4 style={{ lineHeight: "160%", fontFamily: "nunito" }}>
                WITS has partnered with an incredible network of industry
                professionals and is always looking for more passionate tech
                advocates to connect with.
              </h4>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 py-5">
              <img src={mainpic} alt="main pic" width="100%"/>
            </div>
          </div>
        </div>
      </section>
      <img src={yellowwave} className="w-100 mb-5" style={{ marginTop: "-5px" }} alt="yellow-wave" />
      <styledSection>
        <div>
          <div className="container">
            <div className="row">
              <div className="col" style={{ textAlign: "center", paddingTop: "4rem" }}>
                <h2>
                  Companies We've Partnered with
                  <br />
                </h2>
              </div>
            </div>
            <div className="row">
              {/*company cards*/}
              <Company link="https://www.twitter.com" image={twitterlogo} />
              <Company link="https://www.facebook.com" image={fblogo} />
              <Company link="https://www.td.com" image={tdlogo} />
              <Company link="https://www.microsoft.com" image={microsoftlogo} />
              <Company link="https://www.google.com" image={googlelogo} />
              <Company link="https://www.konrad.com" image={konradlogo} />
              <Company link="https://www.cppinvestments.com" image={cpplogo} />
              <Company link="https://www.ibm.com" image={ibmlogo} />
              <Company link="https://www.clearbanc.com" image={cblogo} />
              <Company link="https://www.ubisoft.com" image={ubisoftlogo} />
              <Company link="https://www.lendingloop.ca" image={lllogo} />
              <Company link="https://www.scotiabank.com" image={sblogo} />
            </div>
          </div>
        </div>
      </styledSection>
      <br />
      <br />
      <br />
      <styledSection>
        <div className="container">
          <h2 className="m-2" style={{ textAlign: "center" }}>
            Our Past Partners
          </h2>
          <br />
          <div className=" past-partners-sliderDiv m-2">
            <SliderDiv className="sliderDiv m-auto">
              <Slider type="partners" arr={partnersArr} />
            </SliderDiv>
          </div>
        </div>
      </styledSection>
      <br />
      <br />
      <styledSection>
        <div style={{ background: "#E68976", marginTop: "3%" }}>
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <br />
                <br />
                <br />
                <h2
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontFamily: "helvetica",
                  }}
                >
                  Want to become a partner?
                </h2>
                <br />

                <h5
                  style={{
                    textAlign: "center",
                    color: "white",
                    lineHeight: "160%",
                    fontFamily: "nunito",
                  }}
                >
                  Get in touch and hear more about how you can help us build a
                  future for{" "} women+{" "} in tech
                  <br />
                  <br />
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col m-auto" style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  label="Contact Us"
                  onClick={handleClick}
                  type="tertiary"
                  link="mailto:wits.uwo@gmail.com"
                  style={{ borderRadius: '50px' }}
                />
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </styledSection>
      <styledSection>


      <div style={{ marginTop: "3%" }}>
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <h2 style={{ textAlign: "center" }}> Want to sponsor us? </h2>
                <h5 style={{textAlign: 'center', fontWeight: 'bold', color: '#402B2B'}}> Make sponsorship payments here </h5>
                <br />
                <div className="row justify-content-center">
                  <StyledButton onClick={() => setShow(true)} >Let's do it </StyledButton>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
{/* 

        <div className="container">
          <h2 style={{ textAlign: "center" }}>Ready to join a community of boss women+?</h2>
          <h5 style={{textAlign: 'center', fontWeight: 'bold', color: '#402B2B'}}>Memberships open August 2021 </h5>
          <br />
          <div className="row justify-content-center">
            <StyledButton onClick={() => setShow(true)} >Let's do it </StyledButton>
          </div>
        </div> */}
        <Modal onClose={() => setShow(false)} show={show} /> 
      </styledSection>
    </div>
  );
};
export default Partner;
