// @flow
import React from "react";
import styled from "styled-components";
import "../../styles/styles.css";

const StyledBox = styled.div`
     box-shadow: 0 4px 8px 0 rgba(255,0,0,0.2);
	transition: 0.3s;
	background: white;
	border-radius: 10px;
	height: 100%;
	padding: 10%;
	margin: 2%;
	overflow: hidden;
`;
 export type eventProps = {
   image?: String,
   title: string,
   desc: string,
 };

const PPCard = ({ title, desc,image}:eventProps) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 my-3">
    <StyledBox>
        <div className="container">
            <div style={{textAlign:'center'}}>
                <h3>{title}</h3>
            </div>
            <div className="row">
                <p style={{color:'#0A2338',fontWeight:'400',fontFamily:'Nunito Sans',fontSize:'16px'}}>
                    {desc}
                </p>
                { image != null ? <div className="w-100" style={{textAlign:'center'}} >
									<img src={image} alt="canadian cancer society" class="responsive" />
								</div>:<p></p>}
            </div>
        </div>
    </StyledBox>
</div>
  );
};

export default PPCard;