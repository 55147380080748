import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import Logo from './../../../images/wits.png';
import "./../../../styles/navbar.css";

const Navbar = () => {
  const [color, setColor] = useState({ backgroundColor: "#F1EFE2" });

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    switch (window.location.pathname) {
      case '/':
        setColor({ backgroundColor: "#f1efe2" });
        break;
      case '/membership':
        setColor({ backgroundColor: "#e68976" });
        break;
      case '/about':
        setColor({ backgroundColor: "#b3e2d4" });
        break;
      case '/partners':
        setColor({ backgroundColor: "#f4d089" });
        break;
      case '/mp':
        setColor({ backgroundColor: "#e68976" });
        break;
      case '/blog':
        setColor({ backgroundColor: "#f4d089" });
        break;
      case '/initiatives':
        setColor({ backgroundColor: "#f2d0c8" });
        break;
      case '/events':
        setColor({ backgroundColor: "#b3e2d4" });
        break;
      default:
        setColor({ backgroundColor: "#f1efe2" });
        break;
    }
  }, [color]);

  return (
    <nav className="navbar navbar-light navbar-expand-md fixed-top" style={color}>
      <div className="container">
        <a href="/" className="navbar-brand vertical-center" style={{ padding: '5px auto 2px auto' }}><img src={Logo} alt="she/hacks" /></a>
        <button class="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
          <i className="navbar-toggler-icon"></i>
        </button>

        <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
          <ul className="navbar-nav mr-auto w-100 clearfix">
            <li className="nav-item vertical-center" style={{ padding: '0px' }}>
              <NavDropdown title="INITIATIVES" id="basic-nav-dropdown">
                <NavDropdown.Item href="/initiatives"><b>All Initiatives</b></NavDropdown.Item>
                <NavDropdown.Item href="/events"><b>Events & Educationals</b></NavDropdown.Item>
                <NavDropdown.Item href="/mp"><b>Mentorship & Projects</b></NavDropdown.Item>
                <NavDropdown.Item href="/blog"><b>Blog & Podcast</b></NavDropdown.Item>
              </NavDropdown>
            </li>
            <li className="nav-item vertical-center"><a className="nav-link" href="/partners">PARTNER</a></li>
            <li className="nav-item vertical-center"><a className="nav-link" href="/about">ABOUT US</a></li>
            <li className="nav-item vertical-center"><a className="nav-link" href="/membership"><button className="join-button">JOIN US</button></a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);