import React from "react";
import styled from "styled-components";
import FormatProfile from "./about.profilecard";

//import images of wits members here
import icon from "../images/wits-family/icon-profile.png";
import ameena from "../images/wits-family/Ameena-Zehra.jpg";
import bonnie from "../images/wits-family/Bonnie-Lu.jpg";
import grace from "../images/wits-family/Grace-Tse.jpg";
import isabella from "../images/wits-family/Isabella-Pewarchuk.JPG"
import karen from "../images/wits-family/Karen-Zhan.jpg"
import sharon from "../images/wits-family/Sharon-Peng.jpeg"
import charlotte from "../images/wits-family/Charlotte-Lemon.jpg"
import saanvi from "../images/wits-family/Saanvi-Kapoor.png"
import janice from "../images/wits-family/Janice-Xu.jpeg"
import samantha from "../images/wits-family/Samantha-Su.PNG"
import carrie from "../images/wits-family/Carrie-Lu.jpg"
import mylan from "../images/wits-family/Mylan-Nguyen.jpg"
import lucy from "../images/wits-family/icon-profile.png"
import vikashini from "../images/wits-family/Vikashini-Ravitharan.jpeg"
import joud from "../images/wits-family/Joud-El-Shawa.png"
import reesa from "../images/wits-family/icon-profile.png"
import rena from "../images/wits-family/Rena-Wu.jpg"
import kathy from "../images/wits-family/Kathy-An.jpg";
import jessica from "../images/wits-family/Jessica-Braverman.jpeg";
import sarah from "../images/wits-family/Sarah-You.png";
import nicole from "../images/wits-family/Nicole-Han.png";
import shirleywang from "../images/wits-family/Shirley-W.jpg";
import claire from "../images/wits-family/Claire-Zhang.jpg";
import rhea from "../images/wits-family/Rhea-Mangat.jpg";
import shirleywu from "../images/wits-family/icon-profile.png";
import tasneem from "../images/wits-family/icon-profile.png";
import cindy from "../images/wits-family/cindy-huang.jpeg";
import zoey from "../images/wits-family/zoey-zhang.jpg";
import rutu from "../images/wits-family/rutu-karkare.png";
import ashley from "../images/wits-family/ashley-oyewole.jpg";
import emma from "../images/wits-family/emma-morini.JPG";





import aboutus from "../images/aboutus/aboutus.png";
import tealwave from "../images/aboutus/teal-wave.svg";
import Company from "./company";
import googlelogo from "../images/googlelogo.png";
import mcklogo from "../images/aboutus/companylogos/mclogo.png";
import stripelogo from "../images/aboutus/companylogos/stripelogo.png";
import gslogo from "../images/aboutus/companylogos/gslogo.png";
import MSlogo from "../images/microsoftlogo.png";
import Shopifylogo from "../images/shopifylogo.png";
import dslogo from "../images/dslogo.png";
import cflogo from "../images/cloudfare.png";
import rbclogo from "../images/rbclogo.png";
import tdlogo from "../images/tdlogo.png";
import cpplogo from "../images/cpplogo.png";
import ciscologo from "../images/ciscologo.png";
import avanadelogo from "../images/avanadelogo.png";
import cibclogo from "../images/cibclogo.png";
import belllogo from "../images/belllogo.png";






const styledSection = styled.section`
  padding: 4rem 0 0 0;
`;

const AbtSection = () => {
  return (
    <div style={{ height: "100%", display: "grid", margin: "0", overflow: "hidden" }}>
    <styledSection style={{ background: "#B3E2D4", paddingTop: "7%" }}>
      <div className="container">
        <div className="row reverse-col">
          <div className="col-lg-6 m-auto">
            <h1>About Us</h1>
            <br />
            <h4 style={{ lineHeight: "160%", fontFamily: "nunito" }}>
            At Women In Technology Society, we’re here to support you in your journey through the tech world, join our community today!
            </h4>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 py-5">
            <img src={aboutus} alt="main pic" width="100%" />
          </div>
        </div>
      </div>
    </styledSection>
    <img src={tealwave} className="w-100 mb-5" style={{ marginTop: "-5px" }} alt="teal-wave" />

    <div className="container">
      <div style={{textAlign:'center'}}>
                <h2 style={{fontFamily:"Helvetica",fontStyle:'normal',lineHeight:'140%',color:'#402B2B'}}>
                    Meet the WITS Family
                </h2>
      </div>
      <div className="row" style={{display: "flex",justifyContent: "center", textAlign:'center', fontFamily: "nunito"}}>
            <FormatProfile name="Carrie Lu" title="Co-President" link="https://www.linkedin.com/in/carrielu02/" image={carrie}/>
            <FormatProfile name="Zoey Zheng" title="Co-President" link="https://www.linkedin.com/in/zoey-zheng/" image={zoey}/>
            <FormatProfile name="Bonnie Lu" title="VP of Sponsorships" link="https://www.linkedin.com/in/bonnie-lu/" image={bonnie}/>
            <FormatProfile name="Vikashini Ravitharan" title="Director of Sponsorship" link="https://www.linkedin.com/in/vikashiniravitharan" image={vikashini}/>
            <FormatProfile name="Saanvi Kapoor" title="Director of Sponsorship" link="https://www.linkedin.com/in/saanvi-kapoor-0118021a7/" image={saanvi}/>
            <FormatProfile name="Emma Morini" title="Director of Sponsorship" link="https://www.linkedin.com/in/emma-morini/" image={emma}/>
            <FormatProfile name="Janice Xu" title="Co-chair of SheHacks+" link="http://linkedin.com/in/janicexu16" image={janice}/>
            <FormatProfile name="Sharon Peng" title="Co-Chair of SheHacks+" link="https://www.linkedin.com/in/pengsharon/" image={sharon}/>
            <FormatProfile name="Kathy An" title="Director of SheHacks+" link="https://www.linkedin.com/in/kathy-an/" image={kathy}/>
            <FormatProfile name="Isabella Pewarchuk" title="Director of SheHacks+" link="https://www.linkedin.com/in/isabella-pewarchuk-b24159228/" image={isabella}/>
            <FormatProfile name="Grace Tse" title="Director of SheHacks+" link="https://www.linkedin.com/in/grace-tse" image={grace}/>
            <FormatProfile name="Karen Zhan" title="VP of Marketing" link="https://www.linkedin.com/in/karenzhan" image={karen}/>
            <FormatProfile name="Charlotte Lemon" title="VP of Design" link="https://www.linkedin.com/in/lemoncharlotte/" image={charlotte}/>
            <FormatProfile name="Rutu Karkare" title="Director of Design" link="https://www.linkedin.com/in/rutukarkare/" image={rutu}/>
            <FormatProfile name="Ashley Oyewole" title="Director of Design" link="https://linkedin.com/in/aoyewol2/" image={ashley}/>            
            <FormatProfile name="Samantha Su" title="VP of Finance" link="https://www.linkedin.com/in/samantha-su-4979681b7/" image={samantha}/>
            <FormatProfile name="Jessica Braverman" title="Director of Finance" link="https://www.linkedin.com/in/jessicabraverman" image={jessica}/>
            <FormatProfile name="Sarah You" title="Director of Finance" link="https://www.linkedin.com/in/sarah-you/" image={sarah}/>
            <FormatProfile name="Mylan Nguyen" title="VP of Community" link="https://www.linkedin.com/in/mylan-nguyen/" image={mylan}/>
            <FormatProfile name="Lucy Hur" title="Director of Content" link="https://www.linkedin.com/in/lucy-hur-6aa224198/" image={lucy}/>
            <FormatProfile name="Joud El-Shawa" title="Director of Content" link="https://www.linkedin.com/in/joudelshawa" image={joud}/>
            <FormatProfile name="Shirley Wang" title="VP of Mentorship & Projects" link="https://www.linkedin.com/in/shirleywangg/" image={shirleywang}/>
            <FormatProfile name="Ameena Naqvi" title="Director of Mentorship & Projects" link="https://www.linkedin.com/in/ameena-n/" image={ameena}/>
            <FormatProfile name="Claire Zhang" title="Director of Mentorship & Projects" link="https://www.linkedin.com/in/claire-zhang-673029214/" image={claire}/>
            <FormatProfile name="Nicole Han" title="Director of Mentorship & Projects" link="https://www.linkedin.com/in/nicole-han-2b408919b/" image={nicole}/>
            <FormatProfile name="Reesa Dayani" title="VP of Events & Educationals" link="https://www.linkedin.com/in/reesa-dayani-ab7697172" image={reesa}/>
            <FormatProfile name="Rena Wu" title="VP of Events & Educationals" link="https://www.linkedin.com/in/renawu02/" image={rena}/>
            <FormatProfile name="Cindy Huang" title="Director of Events & Educationals" link="https://www.linkedin.com/mwlite/in/cindy-huang-a2ab801a8" image={cindy}/>
            <FormatProfile name="Tasneem Almohamad" title="VP of Development" link="https://www.linkedin.com/in/tasneem-almohamad/" image={tasneem}/>
            <FormatProfile name="Rhea Mangat" title="Director of Development" link="https://www.linkedin.com/in/rhea-mangat-b5aa81215/" image={rhea}/>
            <FormatProfile name="Shirley Wu" title="Director of Development" link="https://www.linkedin.com/in/w-shirley/" image={shirleywu}/>

      </div>

      
    </div>
    <styledSection>
    <section style={{ backgroundColor: "#F1EFE2", padding: "7% 0", marginTop: "5%" }}>
        <div>
          <div className="container">
            <div className="row">
            <div class="horizontal-center w-100 p-4 m-auto">
					<h2>Where We've Worked</h2>
				</div>		
            </div>
            <div className="row">
              {/*company cards*/}
              <Company link="https://careers.google.com/jobs" image={googlelogo} />
              <Company link="https://www.mckinsey.com" image={mcklogo} />
              <Company link="https://stripe.com/en-ca/jobs" image={stripelogo} />
              <Company link="https://www.goldmansachs.com/careers/" image={gslogo} />
              <Company link="https://careers.microsoft.com/us/en" image={MSlogo} />
              <Company link="https://www.shopify.ca/careers" image={Shopifylogo} />
              <Company link="https://www.docusign.ca/company/careers" image={dslogo} />
              <Company link="https://www.cloudflare.com/careers/" image={cflogo} />
              <Company link="https://jobs.rbc.com/ca/en" image={rbclogo} />
              <Company link="https://jobs.td.com/en-CA/" image={tdlogo} />
              <Company link="https://www.cppinvestments.com/careers" image={cpplogo} />
              <Company link="https://www.cisco.com/c/en/us/about/careers.html" image={ciscologo} />
              <Company link="https://www.avanade.com/en-ca/career" image={avanadelogo} />
              <Company link="https://www.cibc.com/en/about-cibc/careers.html" image={cibclogo} />
              <Company link="https://jobs.bce.ca/bell/" image={belllogo} />
             
            </div>
          </div>
        </div>
        </section>
      </styledSection>
    </div>

  );
};
export default AbtSection;