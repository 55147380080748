//need to import react for every react file
import React from 'react';
import styled from "styled-components";
import './../styles/styles.css';
import './../styles/about.css';


const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 100%;
  &:hover {
    opacity: 0.5;
    transition: all 0.3s ease 0s;
  }
`;
  const FormatProfile = ({ name, title, image, link }) => {
    return (
    <div className="col-lg-2 col-md-4 col-sm-6 col-6 p-4">
      <a href={link} target="_blank" rel="noreferrer">
        <StyledImage className="headshot" src={image} alt="WITS Member" />
      </a>
      <h5 className="horizontal-center headshot-name mt-4" style={{  fontFamily: "Nunito Sans", fontWeight: "600", color: "#0A2338",}}>{name}</h5>
      <h4 style={{  fontWeight: "400", fontSize: "1rem", fontFamily: "Helvetica",  lineHeight: "180%", color:  "#0A2338" }}>{title}</h4>
    </div>


    // <div className="col-lg-2 col-md-4 col-sm-6 col-6 p-4">
    //   <a href={link} target="_blank"> 
    //     <img src={image} className="about-img"/></a>
    //   <h5 className="horizontal-center headshot-name mt-4"style={{ color: '#0A2338'}}> {name} </h5>
    //   <h4 style= {{textAlign: 'center', fontSize: '1rem', fontFamily: 'nunito'}}> {title} </h4>
    // </div>
        
	);
}



//this renders the main app; don't need to do this on all pages -- only on main page (app.js)
//ReactDOM.render(element, document.getElementById("root"));


//when you make a component, you need to return it everytime
//do this for all components; serves as a return value type function
export default FormatProfile;



