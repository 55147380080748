import React, { useState } from "react";
import styled from "styled-components";
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";

const StyledH4 = styled.h4`
  font-family: Helvetica;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
  color: #0a2338;
  text-align: center;
`;
const StyledP = styled.p`
    color: #0A2338;
    font-weight: 400;
    font-family: "Nunito Sans";
    font-size: 16px;
    color: #0A2338;
`;
const ModalDiv = styled.div` 
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ModalContent = styled.div`
    border-radius: 15px;
    background-color: #F1EFE2;;
    width: 500px;
    margin-bottom: 200px;
`;
const StyledLink = styled.a`
    color: #000;
	text-decoration: none;
	transition: background-size .6s;
	background: #E68976;
    background-position: 0 100%;
	background-repeat: repeat-x;
	background-size: 1px 1px;
    &:hover {
        background-size: 4px 50px;
	    text-decoration: none;
	    color: #000;
        font-weight: bold;
    }
`;
const StyledButton = styled.input`
  background: #E68976;
  color: white;
  font-size: 16px;
  padding: 20px 40px;
  border-radius: 50px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  &:hover{
    background-color: #e69f91;
  }
`;

const Modal = props => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [school, setSchool] = useState('');
    const [year, setYear] = useState('');
    const [major, setMajor] = useState('');
    const [pronouns, setPronouns] = useState('');

    const onChangeName = (e) => { setName(e.target.value) }
    const onChangeEmail = (e) => { setEmail(e.target.value) }
    const onChangeSchool = (e) => { setSchool(e.target.value) }
    const onChangeYear = (e) => { setYear(e.target.value) }
    const onChangeMajor = (e) => { setMajor(e.target.value) }
    const onChangePronouns = (e) => { setPronouns(e.target.value) }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        if (name && email && school && year && major && pronouns) {
            // create stripe object
            const stripe = await loadStripe("pk_live_51HRKErI0omsarzuVeaQCilo8REzURIoo5KZUxRd5EVQFdQTQEUgTHbYAylnUYjCeO6dKbmA44uXEhUMSuwQXCD0e001Kg3tvuu");
            // for local testing: http://localhost:4242/create-checkout-session
            const response = await axios.post('https://wits-uwo.ca/api/create-checkout-session', {
                name: name,
                email: email,
                school: school,
                year: year,
                major: major,
                pronouns: pronouns
            })

            if (response.data) {
                stripe.redirectToCheckout({ sessionId: response.data })
                .catch(error => {
                    // if redirectToCheckout fails due to a browser or network error, dsisplay the error to customer
                    console.log("Error: " + error);
                });
            }
        }
    };

    if (!props.show) {
        return null;
    }

    return (
        <div style={{ zIndex: '5000', position: "relative"}}>
            <ModalDiv className="modalroundedcorners" onClick={props.onClose}>
                <ModalContent className="py-4 px-5" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close text-right" onClick={props.onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <br />
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <StyledH4>Please use your university email as WITS memberships are only open to students.</StyledH4>
                        <br />
                        <StyledP style={{ fontWeight: "normal", fontSize: "14px" }}>
                            Certain member benefits are only available to female/non-binary and Western University students. <br />
                            <StyledLink href="/membership">Please click here for more information</StyledLink>
                        </StyledP>
                    </div>
                    <div className="text-center">
                        <form onSubmit={onFormSubmit}>
                            <label>
                                <span style={{ fontSize: "15px" }}> Full Name </span>
                                <input type="text" class="field" id="name-input" style={{ borderRadius: "5px" }} value={name} onChange={onChangeName} />
                            </label>
                            <label>
                                <span style={{ fontSize: "15px" }}> School Email </span>
                                <input type="email" class="field" id="email-input" style={{ borderRadius: "5px" }} value={email} onChange={onChangeEmail} />
                            </label>
                            <label>
                                <span style={{ fontSize: "15px" }}> School </span>
                                <input type="text" class="field" id="email-input" style={{ borderRadius: "5px" }} value={school} onChange={onChangeSchool} />
                            </label>
                            <label>
                                <span style={{ fontSize: "15px" }}> Year </span>
                                <input type="text" class="field" id="email-input" style={{ borderRadius: "5px" }} value={year} onChange={onChangeYear} />
                            </label>
                            <label>
                                <span style={{ fontSize: "15px" }}> Major </span>
                                <input type="text" class="field" id="email-input" style={{ borderRadius: "5px" }} value={major} onChange={onChangeMajor} />
                            </label>
                            <label>
                                <span style={{ fontSize: "15px" }}> Pronouns </span>
                                <input type="text" class="field" id="email-input" style={{ borderRadius: "5px" }} value={pronouns} onChange={onChangePronouns} />
                            </label>
                            <br />
                            <div className="row justify-content-center">
                                <StyledButton type='submit' value='Continue to Checkout' />
                            </div>
                        </form>
                    </div>
                </ModalContent>
            </ModalDiv>
        </div>
    );
}

export default Modal;