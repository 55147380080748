import React from 'react';
import Styled from 'styled-components';

import Button from '../button/Button';

/*
Initiatives card doubles as both a portfolio card and upcoming events card.
- for portfolio card: set the date to an empty string
- for upcoming events card: pass in a date props
*/

const StyledCard = Styled.div`
    box-shadow: 0 8px 16px 0 rgba(255,0,0,0.2);
    border:none;
    border-radius:20px;
    height: 300px; 
`;

const StyledHorizontalCard = Styled.div`
    display: flex;
  	flex: 1 1 auto; 
    background: #fff;
    border-radius:20px;
    
`;

const StyledCardBody = Styled.div`
    align: left:
    padding: 5%;
    width: 100%;
    height: 300px;
`;

const StyledH4 = Styled.h4`
    font-size: 20px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
    color: #0A2338;
`;

const StyledH6 = Styled.h6`
    font-weight: bold;
    font-family: "Nunito Sans";
    font-size: 16px;
    color: #0A2338;
`;

const StyledP = Styled.p`
    color: #0A2338;
    font-weight: 400;
    font-family: "Nunito Sans";
    font-size: 16px;
    color: #0A2338;
`;

const portfolio = (props) => {
    const { title, date, desc, buttonLabel, onClickHandler, link, image} = props;

    return(
        <div className="container" style={{marginTop: '2%', marginBottom: '3%'}}>
            <StyledCard>
                <StyledHorizontalCard>
                    <StyledCardBody className="py-5 px-5">
                        <StyledH4>{title}</StyledH4>
                        { date ? <StyledH6>{date}</StyledH6> : null }
                        <StyledP>{desc}</StyledP>
                        {buttonLabel ? <Button label={buttonLabel} size='medium' onClick={onClickHandler} link={link}/> : null }
                    </StyledCardBody>
                    {image ? <img className="d-none d-lg-block " src={image} alt="Card"/> : null }
                </StyledHorizontalCard>
            </StyledCard>
        </div>

    )

}

export default portfolio;