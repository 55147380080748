import React, { Component } from "react";
import Slider from "react-slick";
import Events from "./../events/events";
import MainEvent from "./../../../main/comp/eventcard";
import PartnerCard from "../../../partner/cards/partner";
import MPCard from "../../../mp/comp/slidercard";


export default class Responsive extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    arrows: false,
                }
            }]
        };

        if (this.props.type === "main") {
            return (
                <div>
                    <Slider {...settings}>
                        {
                            this.props.arr.map(element => {
                                return <MainEvent title={element[0]} desc={element[1]} date={element[2]} />
                            })
                        }
                    </Slider>
                </div>
            )
        }

        if (this.props.type === "events") {
            return (
                <div>
                    <Slider {...settings}>
                        {
                            this.props.arr.map(element => {
                                return <Events title={element[0]} desc={element[1]} date={element[2]} />
                            })
                        }
                    </Slider>
                </div>
            )
        }

        if (this.props.type === "mp") {
            return (
                <div>
                    <Slider {...settings}>
                        {
                            this.props.arr.map(element => {
                                return <MPCard title={element[0]} desc={element[1]} date={element[2]} />
                            })
                        }
                    </Slider>
                </div>
            )
        }

        if (this.props.type === "partners") {
            return (
                <div>
                    <Slider {...settings}>
                        {
                            this.props.arr.map(element => {
                                return <PartnerCard link={element[0]} image={element[1]} name={element[2]} company={element[3]} position={element[4]} />
                            })
                        }
                    </Slider>
                </div>
            )
        }
    }
}