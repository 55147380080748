import React from "react";
import "../../styles/styles.css";


const MPCard = ({ title, desc}) => {
  return (
    <div class="slider m-auto" >
      <div class="event-card m-3" style={{ width: '250px', height: '350px'}}>
        <div class="card-content">
          <h3>{title}</h3>
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
};
export default MPCard;