import React from "react";
import "../styles/styles.css";
import "../styles/main.css";
import Slider from './../common/components/slider/Slider';
import styled from "styled-components";
import Button from "../common/components/button/Button";
import mainpic from "../images/mainpic.png";
import wave from "../images/header-waves/beige-wave.svg";
import empower from "../images/empoweringwomen.png";
import build from "../images/buildingnetworks.png";
import create from "../images/creatingpipelines.png";
import redWaveFlipped from "../images/header-waves/red-wave-flipped.svg"
import woman from '../images/woman.png';
import trailblazer from '../images/trailblazehers.png';
import { LinkTo } from "@storybook/addon-links";
import { Link } from "react-router-dom";
import ghosting from "../images/ghosting.jpg"

const eventsArr = [
  [
    "Shehacks+ 7",
    "Junary 13-15th, 2023",
    "Canada's biggest all-female and non-binary 36-hour hackathon."
  ],
  [
    "No More Ghosting",
    "November 9th, 2022",
    "A guide to networking amied to cover the steps and strategies of the typical recruitment process."
  ],
  [
    "AGM",
    "October 3rd, 2022",
    "An opportunity to learn about WITS+ and what it has to offer, including a detailed overview of its future initiatives."
  ],
]

const StyledSection = styled.section`
  padding: 4rem 0 0 0;
`;
const Main = () => {
  function handleClick() {
    console.log("click clack");
  }
  return (
    <div style={{ height: "100%", display: "block", margin: "0", overflow: "hidden" }}>
      <section style={{ backgroundColor: "#F1EFE2", paddingTop: '10%' }}>
        <div className="container">
          <div className="row reverse-col">
            <div className="col-lg-6 m-auto">
              <h1>Building a Future for Women+ in Tech</h1>
              <br />
              <h4
                style={{
                  lineHeight: "160%",
                  fontFamily: "Nunito",
                  color: "#0A2338",
                }}
              >
                At Women in Technology Society, we’re here to support you in
                your journey through the tech world, join our community today!
              </h4>
              <br />
              <Button
                type="primary"
                label="Join our community"
                size="large"
                onClick={handleClick}
                link="/membership"
              />
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-sm-12 col-12 m-auto py-5 horizontal-center">
              <img
                className="mobileimg"
                src={mainpic}
                alt="main pic"
                width="100%"
              />
            </div>
          </div>
        </div>
      </section>
      <img src={wave} className="w-100 mb-5" style={{ marginTop: "-5px" }} alt="wave" />
      <StyledSection>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 m-auto">
              <br />
              <img src={empower} className="mobileimg2" width="100%" alt="pic" />
              <br />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 m-auto">
              <br />
              <img src={build} className="mobileimg2" width="100%" alt="pic" />
              <br />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 m-auto">
              <br />
              <img src={create} className="mobileimg2" width="100%" alt="pic" />
              <br />
            </div>
          </div>
        </div>
      </StyledSection>
      <br />
      <br />
      <StyledSection>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <h3>EVENTS</h3>
              <h2>
                Upcoming
                <br />
                Events
              </h2>
              <br />
              <Button
                type="primary"
                label="See all events"
                size="large"
                onClick={handleClick}
                link="/events"
              />
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-12">
              <Slider type="main" arr={eventsArr} />
            </div>
          </div>
        </div>
      </StyledSection>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <img src={redWaveFlipped} class="w-100" style={{ marginBottom: '-5px' }} alt="wave"></img>
      <div style={{background: '#E68976', marginBottom: '3%'}}>

        <div class="container">
          <div class="container">
            <div class="row reverse-col ">
              <div class="col m-auto" >
                <img src={woman} class="d-none d-lg-block m-auto" width="60%" alt="main"/>
              </div>

              <div class="col  m-auto" >
                <div class="elementpadding">

                  <h4 style={{color: 'white', fontWeight: 'bold'}}>Mentorship Programs</h4>
                  <br />
                  <h6 style={{color: 'white', lineHeight: '170%', fontFamily: 'Nunito'}}>
                    Our Ada Mentorship, Big CSter, and Hack with Heart programs will provide a strong network of mentors to help you build a project and arm you 
                    with skills to excel in the industry</h6>
                  <br />
                  <Button size="large" label="Learn more" type="secondary" link="/mp" />
                </div>

              </div>
            </div>
            <br />
            <br />
            <div class="row reverse-col">

              <div class="col mx-auto m-auto"  >
                <div class="elementpadding">
                  <h4 style={{color: 'white', fontWeight: 'bold'}}>Blog and Podcast</h4>
                  <br />
                  <h6 style={{color: 'white', lineHeight: '170%', fontFamily: 'Nunito'}}>Hear and read the thoughts of powerful women in tech!Join us as we delve into their stories and insights.</h6>
                  <br />
                  <Button size="large" label="Learn more" type="secondary" link="/blog" />
                </div>
              </div>

              <div class="col ">
                <div class="vertical-center">
                  <img src={trailblazer} class="d-none d-lg-block m-auto" width="60%" alt="main"/>
                </div>
              </div>
            </div>
            <br /><br /><br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
