import React from 'react';
import Slider from './../common/components/slider/Slider';
import PastEventCard from '../common/components/events/pastEvent';
import './../styles/styles.css';


import SheHacks6 from './../images/events/SheHacks6.jpg'
import LinkedInRevamp from './../images/events/LinkedInRevamp.jpg'
import IntroToNFTs from './../images/events/IntroToNFTS.jpg'
import CookiesAndCocoa from './../images/events/CookiesAndCocoa.jpg'
import TechExpoPanel from './../images/events/TechExpoPanel.jpg'
import NoMoreGhosting from './../images/events/NoMoreGhosting.jpg'
import WhatIKnew from './../images/events/WhatIKnew.jpg'
import IntroToAPIs from './../images/events/IntroToAPIs.jpg'

const eventsArr = [
    [
        "Shehacks+ 7",
        "Canada's biggest all-female and non-binary 36-hour hackathon.",
        "Junary 13-15th, 2023"
    ],
    [
        "No More Ghosting",
        "A guide to networking amied to cover the steps and strategies of the typical recruitment process.",
        "November 9th, 2022"
    ],
    [
        "AGM",
        "An opportunity to learn about WITS+ and what it has to offer, including a detailed overview of its future initiatives.",
        "October 3rd, 2022"
    ],
]

const Events = () => {
    return (
        <div style={{ height: "100%", display: "block", margin: "0", overflow: "hidden" }}>
            <section style={{ paddingTop: '12%', paddingBottom: '5%', backgroundColor: '#B3E2D4' }}>
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-lg-6 m-auto">
                            <h1>Events & <br />Educationals</h1>
                        </div>
                        <div className="col-lg-6 py-5">
                            <h4 style={{ fontFamily: 'nunito' }}>
                                We provide our community with access to informative panels, educationals, workshops,
                                inspirational speakers.
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <br />
            <div className="container">
                <h3>EVENT SERIES</h3>
                <div style={{ backgroundColor: '#F1EFE2', borderRadius: '20px', height: '450px' }}>
                    <div class="slider m-3" style={{ paddingTop: '50px' }}>
                        <Slider type="events" arr={eventsArr} />
                    </div>
                </div>
            </div>
            <div className="marginTop5Footer">
                <div className="container">
                    <h3 >A LOOK BACK</h3>
                    <h2 >Past Events</h2>

                    {/* 4x2 table of past events */}
                    <div className="row" style={{ flexWrap: "unset" }}>
                    <PastEventCard
                            image={IntroToNFTs}
                            title="Intro to NFTs"
                            desc="Level up your understanding of NFTs! We’ll teach you to create your own NFT and digital wallet to launch your NFT on the market!"
                            link="https://www.facebook.com/events/617938599296968/?active_tab=about"
                        />

                        <PastEventCard
                            image={LinkedInRevamp}
                            title="New Year New Me: LinkedIn Revamp + Build a Personal Portfolio Website"
                            desc="Learn how to revamp your LinkedIn profile to attract recruiters and how to use HTML, CSS, and JavaScript to make a portfolio/resume website + deploy it online!"
                            link ="https://www.facebook.com/events/3163207773950710/?active_tab=about"
                        />
                        <PastEventCard
                            image = {SheHacks6}
                            title = "SheHacks 6"
                            desc= "It’s time for SheHacks+ VI, one of Canada’s largest 36-hour hackathon for women and non-binary individuals!" 
                            link="https://www.facebook.com/events/1392498147818572/?active_tab=about"
                        />
                        <PastEventCard
                            image={CookiesAndCocoa}
                            title="Cookies and Cocoa"
                            desc="Take your study breaks with our first IN-PERSON event where you can lay back and snack on some cookies alongside your own mug of hot chocolate!"
                            link="https://www.facebook.com/events/637451484115255/?active_tab=about"
                        />
                        {/*<PastEventCard
                            image={ResumeRevamp}
                            title="Resume Revamp"
                            desc="The Recruiting Series: Resume Revamp as the 1st of our 3-part series!"
                            link="https://www.facebook.com/events/960607994439966/?acontext=%7B%22source%22%3A5%2C%22action_history%22%3A[%7B%22surface%22%3A%22page%22%2C%22mechanism%22%3A%22main_list%22%2C%22extra_data%22%3A%22%5C%22[]%5C%22%22%7D]%2C%22has_source%22%3Atrue%7D"
                        />
                        <PastEventCard
                            image={Hardware}
                            title="Intro to Hardware"
                            desc="Going through prototyping and working with small electrical components!"
                            link="https://www.facebook.com/events/591979428307518/?acontext=%7B%22source%22%3A5%2C%22action_history%22%3A[%7B%22surface%22%3A%22page%22%2C%22mechanism%22%3A%22main_list%22%2C%22extra_data%22%3A%22%5C%22[]%5C%22%22%7D]%2C%22has_source%22%3Atrue%7D"
                        />
                        <PastEventCard
                            image={PythonPic}
                            title="Intro To Python"
                            desc="Teaching you the basics of coding with Python lead by Google interns!"
                            link="https://www.facebook.com/events/456359888561224/?acontext=%7B%22source%22%3A5%2C%22action_history%22%3A[%7B%22surface%22%3A%22page%22%2C%22mechanism%22%3A%22main_list%22%2C%22extra_data%22%3A%22%5C%22[]%5C%22%22%7D]%2C%22has_source%22%3Atrue%7D"
                        />
                        <PastEventCard
                            image={DataScience}
                            title="BMO Data Science"
                            desc="Learn the tools used by data scientists to collect, clean, analyze and model data!"
                            link="https://www.facebook.com/events/1942849662468338/?acontext=%7B%22source%22%3A5%2C%22action_history%22%3A[%7B%22surface%22%3A%22page%22%2C%22mechanism%22%3A%22main_list%22%2C%22extra_data%22%3A%22%5C%22[]%5C%22%22%7D]%2C%22has_source%22%3Atrue%7D"
                        /> */}
                    </div>
                    <div className="row" style={{ flexWrap: "unset" }}>
                    <PastEventCard
                            image={TechExpoPanel}
                            title="WITS+ Tech Expo Panel"
                            desc="Learn about non-traditional roles such as DevOps Engineering, UI/UX Design, Product Management and more from industry professionals at your favourite tech companies!"
                            link="https://www.facebook.com/events/470056644429234/?active_tab=about"
                        />
                        <PastEventCard
                            image={NoMoreGhosting}
                            title="No More Ghosting: Intro to Networking + Tech Interviews                            "
                            desc="Discover the typical recruiting timeline and meet a lineup of panelists from different companies that will share their tips and tricks!"
                            link="https://www.facebook.com/events/528195255288356/?active_tab=about"
                        />
                        <PastEventCard
                            image={WhatIKnew}
                            title="What I Wish I Knew In Uni: Tech Industry Edition                            "
                            desc="Hear from fellow software engineers, PMs, and more on their personal journeys into tech, and what they wish they knew in university"
                            link="https://www.facebook.com/events/663395484637990/?active_tab=about"
                        />
                        <PastEventCard
                            image={IntroToAPIs}
                            title="Industry Tools you Don't Learn in School: Intro to APIs                            "
                            desc="This event will teach everything you need to know about APIs and how to use them to LEVEL UP your side project game"
                            link="https://www.facebook.com/events/894478978172170/?active_tab=about"
                        />
                        {/*<PastEventCard
                            image={IBMData}
                            title="Data Analytics Workshop"
                            desc="Learn how to explore data build models and perform tests to find insights!"
                            link="https://www.facebook.com/events/3499096986850271/?acontext=%7B%22ref%22%3A%2252%22%2C%22action_history%22%3A%22[%7B%5C%22surface%5C%22%3A%5C%22share_link%5C%22%2C%5C%22mechanism%5C%22%3A%5C%22share_link%5C%22%2C%5C%22extra_data%5C%22%3A%7B%5C%22invite_link_id%5C%22%3A195419145369608%7D%7D]%22%7D"
                        />
                        <PastEventCard
                            image={FiresideChat}
                            title="Fireside Chat for Int'l Women's Day"
                            desc="Learn about a variety of topics including entrepreneurship, women in the workplace and how to overcome obstacles that may come your way"
                            link="https://www.facebook.com/events/226695255845302/?ref=newsfeed"
                        />
                        <PastEventCard
                            image={SheHacks55}
                            title="SheHacks 5.5"
                            desc="We encourage all female and non-binary students from all skill levels, majors, and backgrounds to attend"
                            link="https://www.facebook.com/events/468771864544532/?ref=newsfeed"
                        />
                        <PastEventCard
                            image={CookiesCode}
                            title="Alternative Tech Panel"
                            desc="Want to learn more about the intersection of technology and other industries like healthcare, education and retail? Attend the Alternative Tech Panel"
                            link="https://www.facebook.com/events/872096363339433/?ref=newsfeed"
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events;