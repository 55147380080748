// @flow
import React from "react";
import styled from "styled-components";
const StyledBox = styled.div`
   margin-top: 2%;
   margin-right: 3%;
   padding: 0;
   flex: 1 1 auto;
   border: none;
   border-radius: 5px;
   box-shadow: 0 8px 16px 0 rgb(255 0 0 / 20%);
   font-family: 'Open Sans', sans-serif;
   color: #1F1E3F;
   cursor: pointer;

   &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      border: none;
      text-decoration: none;
   }
`;
const StyledText = styled.div`
   flex-direction: column;
   align-items: start;
   min-height: 250px;
   padding: 20px
`;
const StyledImage = styled.img`
   display: block;
   width: 100%;
   height: 200px;
   object-fit: cover;
`;
const StyledHeading = styled.h4`
   margin: 10px 0 15px;
   font-size: 24px;
`;
const StyledP = styled.p`
   margin: 0 0 20px;
   font-size: 16px;
   line-height: 1.6;
`;
 export type eventProps = {
   image: String,
   title: string,
   desc: string,
   link: string,
 };

const PastEventCard = ({ image, title, desc, link }:eventProps) => {
  return (
      <StyledBox className="col-lg-3 col-sm-6 col-6" style={{marginTop: '2%'}}>
        <a style={{textDecoration: "none"}} href={link} rel="noreferrer" target="_blank">
          <StyledImage className="card-img-top" src={image} alt="Card image cap"/>
          <StyledText>
            <StyledHeading>{title}</StyledHeading>
            <StyledP>{desc}</StyledP>
          </StyledText>
        </a>
      </StyledBox>
  );
};

export default PastEventCard;