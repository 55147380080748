import React, { useState, useEffect } from "react";
import { Container } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import styled from "styled-components";
import axios from "axios";

import Button from './../../common/components/button/Button';
import gif from './../../images/success.gif';

const SuccessDiv = styled.div`
  @media (max-width: 920px){
    padding-top: 16%;
  }
`;


const SuccessPage = () => {
    const { id } = useParams();
    const [name, setName] = useState('');

    useEffect(() => {
        async function fetchData() {
            const res = await axios.get('https://wits-uwo.ca/api/fetch-checkout-session', { params: { sessionId: id } });
            setName(res.data.metadata.customer_name);
        }
        fetchData();
    }, [id]);

    const formatName = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }

    return (
        <SuccessDiv>
            <div >
                <img alt="success" style={{ display: 'block', marginTop: '7%', marginLeft: 'auto', marginRight: 'auto', width: '150px' }} src={gif} />
            </div>
            <div className="text-center">
                <p style={{ fontWeight: 'normal', fontSize: '18px' }}>
                    <strong style={{ fontSize: '22px' }}>
                        Welcome to the WITS family, {formatName(name)}!
                    </strong>
                    <br />
                    A receipt will be emailed to you shortly.
                </p>
            </div>
            <br />
            <div className="row justify-content-center">
                <Button type="membership" label="Home" size="membership" link="/" onClick={() => { console.log("Join Slack :))") }} />
            </div>
            <br />
        </SuccessDiv>
    );
}


export default SuccessPage;
